import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de copinya blanquinós, sense peu o rudimentari, amb un capell de fins a 3 cm de diàmetre. La part superior és una mica peludeta, donant-li una aparença llanuda. El marge no és uniforme, està retorçat i un poc plegat sobre ell mateix a un dels costats. Està enrotllat llarg temps. Les làmines blanquinoses i primes, no gaire juntes i disposades radialment. Les espores són terroses clares en massa, el·lipsoïdals de 5-7 x 2,7-3,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      